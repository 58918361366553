import React from "react";
import Nav from '../components/Nav';
import HomeHeader from '../components/HomeHeader';
import SEO from '../components/SEO';
import Footer from '../components/Footer';
import { Link, graphql } from "gatsby";
import Img from "gatsby-image";

export default function Home({data}) {
	return (
		<div>
			<SEO 
				title="unDraw Creatives"
				description="A blog about open-source design and creativity."
			/>
			<Nav />
			<HomeHeader />
			<div className='container'>
				<div className="posts">
					<h4 className='posts__title'>Latest Posts</h4>
					<div className='postlist'>
						{data.allMarkdownRemark.edges.map(({ node }) => node.frontmatter.title !== 'unDraw for Adobe Express' && (
							<div key={node.id} className='postlist__item'>
								<Link to={node.fields.slug}>
								<div className='postlist__item__media'>
									<Img fluid={node.frontmatter.img.childImageSharp.fluid}
										className='postlist__item__img' alt={node.frontmatter.title + " image"}
									 />
								</div>
								</Link>
								<div className='postlist__item__data'>
									<div className='postlist__item__data__category'>{node.frontmatter.category}</div>
								</div>
								<h3 className='postlist__item__title'>{node.frontmatter.title}</h3>
								<p className='postlist__item__description'>{node.frontmatter.description}</p>
							</div>
						))}
						<div className='postlist__item--ghost'></div>
						<div className='postlist__item--ghost'></div>
						<div className='postlist__item--ghost'></div>
					</div>
				</div>
			</div>
			<Footer />
		</div>
	)
}

export const query = graphql`
	query {
		allMarkdownRemark (sort: { fields: [frontmatter___date], order: DESC }){
			edges {
				node {
					id
					frontmatter {
						category
						title
						description
						img {
							childImageSharp {
								fluid(maxWidth: 355) {
									...GatsbyImageSharpFluid
								}
							}
						}
					}
					fields {
						slug
					}
				}
			}
		}
	}
`