import React from "react";
//import { Link } from 'gatsby';

export default function HomeHeader() {
	return (
		<header className='page container'>
			<div className='header'>
				<div className='header__main'>
					<h1 className='header__title'>A blog about open-source design and creativity.</h1>
					<ul className='header__nav'>
						<li><a href='https://undraw.co/illustrations'>Illustrations</a></li>
						<li><a href='https://plus.undraw.co'>Supporters</a></li>
						<li><a href='https://handcrafts.undraw.co'>Handcrafts</a></li>
						{/*<li><Link to='/open_a_project'>Open your work</Link></li>*/}
					</ul>
				</div>
			</div>
		</header>
	)
}
